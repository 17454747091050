<template>
    <modal ref="crearOrdenCompra" titulo="Crear orden de compra" tamano="modal-lg" adicional="Crear" @adicional="crearOrdenCompra">
        <div class="container">
            <div class="row f-14 my-4 mx-0">
                <div class="col-6">
                    <p class="f-600 pl-4">
                        Forma de pago:
                        <span class="f-300"> Cuotas </span>
                    </p>
                </div>
                <div class="col-6 d-flex">
                    <p>Fijar cuota por:</p>
                    <div class="my-auto ml-2">
                        <el-radio v-model="radioPorcentajeValor" label="1" class="f-12">Porcentaje</el-radio>
                        <el-radio v-model="radioPorcentajeValor" label="2" class="f-12">Valor</el-radio>
                    </div>
                </div>
            </div>
            <div class="row mt-4 mx-0 bg-general text-white py-2">
                <div class="col-2 my-auto">
                    <p class="f-600 f-14">Cuotas</p>
                </div>
                <div class="col-4 my-auto">
                    <p class="f-600 f-14">Fecha de pago</p>
                </div>
                <div v-if="radioPorcentajeValor === '1'" class="col-2 my-auto">
                    <p class="f-600 f-14">Porcentaje</p>
                </div>
                <div v-else class="col-3 my-auto">
                    <p class="f-600 f-14">Valor</p>
                </div>
                <div class="col-auto my-auto ml-auto">
                    <i class="icon-plus-box cr-pointer f-20" />
                </div>
            </div>
            <div v-for="(data,i) in 4" :key="i" class="row border-bottom mx-0 py-2">
                <div class="col-2 my-auto">
                    <p class="f-12">Cuota {{ i+1 }}</p>
                </div>
                <div class="col-4 my-auto">
                    <el-date-picker v-model="datePicker" type="date" class="w-100" size="small" placeholder="Seleccionar un día" />
                </div>
                <div v-if="radioPorcentajeValor === '1'" class="col-2 my-auto">
                    <el-input v-model="inputPorcentaje" class="w-100" size="small" placeholder="%" />
                </div>
                <div v-else class="col-3 my-auto">
                    <el-input v-model="inputValorCuota" class="w-100" size="small" placeholder="Valor" />
                </div>
                <div class="col-auto my-auto ml-auto">
                    <i class="icon-close-circle cr-pointer f-20 text-ddd" />
                </div>
            </div>
            <div class="row mt-4 mx-0 py-2">
                <div class="col-2 my-auto" />
                <div class="col-4 my-auto text-right">
                    <span class="f-600 f-14 border br-4 bg-f5 px-3 py-2 text-center">Total</span>
                </div>
                <div v-if="radioPorcentajeValor === '1'" class="col-2 my-auto">
                    <div class="f-600 f-14 border br-4 bg-f5 px-3 py-2 text-center">100%</div>
                </div>
                <div v-else class="col-3 my-auto">
                    <div class="f-600 f-14 border br-4 bg-f5 px-3 py-2 text-center">US $ 85.000,00</div>
                </div>
                <div class="col-auto my-auto ml-auto" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            radioPorcentajeValor: '2',
            datePicker: '',
            inputPorcentaje: '',
            inputValorCuota: '',
        }
    },
    methods: {
        toggle(){
            this.$refs.crearOrdenCompra.toggle()
        },
        crearOrdenCompra(){
            this.toggle()
            this.$router.push({ name: 'compras.ver.orden' })
        }
    }
}
</script>

<style lang="css" scoped>
</style>
